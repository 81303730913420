<template>
  <el-dialog :title="dialogTitle" :visible.sync="show" width="590px" :close-on-click-modal="false" @close="handelClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" v-loading="dialogLoading">
      <el-row>
        <el-col :span="24">
          <el-form-item label="检查表名称" prop="name" required>
            <el-input v-model="ruleForm.name" placeholder="请输入" style="width:364px" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="简称" prop="nickname" required>
            <el-input v-model="ruleForm.nickname" placeholder="请输入" style="width:364px" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="分值" prop="points" required>
            <el-input v-model="ruleForm.points" placeholder="请输入" style="width:364px" clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button @click="resetForm">取消</el-button>
      <el-button @click="submitForm" type="primary" :loading="btnLoading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash';
import { getItem } from '@/utils/storage.js';
import { validateRules } from './validateRules.js';
import { addOrEditSafetyCheck } from 'api/super-admin/sys-setting/score-setting.js';
export default {
  name: 'AddDialog',
  mixins: [validateRules],
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      dialogLoading: false,
      btnLoading: false,
      ruleForm: {},
      show: this.dialogVisible
    };
  },
  methods: {
    initFormDetail(row) {
      this.ruleForm = row;
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addOrEditSafetyCheck();
        }
      });
    },
    resetForm() {
      this.dialogCloseCommomFunc();
    },
    handelClose() {
      this.dialogCloseCommomFunc();
    },
    dialogCloseCommomFunc() {
      this.$emit('update:dialogVisible', false);
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },
    addOrEditSafetyCheck() {
      //编辑或者新增接口
      this.btnLoading = true;
      const params = cloneDeep(this.ruleForm);
      addOrEditSafetyCheck(Object.assign(params, { tenantId: getItem('orgId') }))
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', true);
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    }
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    }
  }
};
</script>
