import request from '@/utils/request.js';
/**
 * 施工安全检查评分表列表
 * @returns { Promise }
 */
export function getSafetyCheckList(data) {
  return request({
    url: '/traffic-construction/safetyCheck/list',
    method: 'post',
    data
  });
}

/**
 * 添加/修改施工安全检查评分表
 * @returns { Promise }
 */
export function addOrEditSafetyCheck(data) {
  return request({
    url: '/traffic-construction/safetyCheck/addOrEdit',
    method: 'post',
    data
  });
}

/**
 * 删除施工安全检查评分表
 * @returns { Promise }
 */
 export function deleteSafetyCheck(data) {
  return request({
    url: '/traffic-construction/safetyCheck/delete',
    method: 'post',
    data
  });
}

/**
 * 添加/修改检查表明细表(多条)
 * @returns { Promise }
 */
 export function addOrEditListCheckDetail(data) {
  return request({
    url: '/traffic-construction/checkDetail/addOrEditList',
    method: 'post',
    data
  });
}

/**
 * 检查表明细表列表
 * @returns { Promise }
 */
 export function getCheckDetailList(data) {
  return request({
    url: '/traffic-construction/checkDetail/list',
    method: 'post',
    data
  });
}

/**
 * 删除检查表明细表
 * @returns { Promise }
 */
 export function deleteCheckDetail(data) {
  return request({
    url: '/traffic-construction/checkDetail/delete',
    method: 'post',
    data
  });
}