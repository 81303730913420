<template>
  <el-dialog
    title="修改检查表"
    :visible.sync="checkVisible"
    :close-on-click-modal="false"
    @close="dialogClose"
    width="1024px"
  >
    <!-- 表名 -->
    <p class="tabel-tilte">{{ title }}</p>
    <el-table
      :data="tableCheckData"
      style="width: 100%"
      size="medium"
      border
      v-loading="tableCheckLoading"
      max-height="485px"
    >
      <el-table-column prop="checkItem" label="检查项目" align="center" width="250">
        <template slot-scope="{ row }">
          <el-input v-model="row.checkItem" placeholder="点击输入"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="deductionStandard" label="扣分标准" align="center">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.deductionStandard"
            placeholder="点击输入"
            type="textarea"
            :maxlength="500"
            :autosize="{ minRows: 6 }"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="应得分数" align="center" width="150">
        <template slot-scope="{ row }">
          <el-input v-model="row.score" placeholder="点击输入"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="120" align="center">
        <template slot-scope="{ row, $index }">
          <span class="view-details" @click="deleteConfig(row.id, $index)" style="color:#F56C6C">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="footer-btn-grounp">
      <div class="add-btn" @click="handelConfigCheck">添加配置项</div>
      <div>
        <el-button @click="handelClose">取消</el-button>
        <el-button type="primary" @click="handleCheckConfig" :loading="btnLoading">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  addOrEditListCheckDetail,
  getCheckDetailList,
  deleteCheckDetail
} from 'api/super-admin/sys-setting/score-setting';
import { getItem } from '@/utils/storage.js';
export default {
  name: 'ConfigHiddenDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data() {
    return {
      btnLoading: false,
      checkVisible: this.dialogVisible,
      tableCheckLoading: false,
      tableCheckData: [],
      title: '', //表名
      checkId: '' //检查表id
    };
  },
  methods: {
    initTabelConfigFunc(id, name) {
      //父祖传访问子组件传入的值
      this.checkId = id;
      this.title = name;
      this.getCheckDetailList();
    },
    handelConfigCheck() {
      //添加项
      let obj = {
        checkId: this.checkId,
        tenantId: getItem('orgId'),
        checkItem: '',
        deductionStandard: '',
        score: ''
      };
      this.tableCheckData.push(obj);
    },
    deleteConfig(id, index) {
      //删除
      this.$confirm('确认是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          this.deleteCheckDetail(id);
        } else {
          this.tableCheckData.splice(index, 1);
        }
      });
    },
    handleCheckConfig() {
      //确定
      if (!this.fillValidateFunc()) {
        return false;
      }
      this.addOrEditListCheckDetail();
    },
    fillValidateFunc() {
      //判断每行数据是否都输入，返回Boolean值
      let flag = true;
      this.tableCheckData.find(item => {
        if (!item.checkItem || !item.deductionStandard || !item.score) {
          this.$message.warning('请填写完整');
          flag = false;
        }
        if (item.checkItem.length > 20) {
          this.$message.warning('检查项目最大字符为20');
          flag = false;
        }
        if (!Number.isInteger(Number(item.score)) || Number(item.score) < 0 || Number(item.score) > 100) {
          this.$message.warning('应得分数（请输入0-100的整数）');
          flag = false;
        }
      });
      return flag;
    },
    handelClose() {
      this.$emit('update:dialogVisible', false);
    },
    dialogClose() {
      this.$emit('update:dialogVisible', false);
    },
    //添加/修改检查表明细表(多条)
    addOrEditListCheckDetail() {
      this.btnLoading = true;
      addOrEditListCheckDetail({ checkDetailParamList: this.tableCheckData })
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$emit('update:dialogVisible', false);
            this.$message.success('操作成功');
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //检查表明细表列表接口
    getCheckDetailList() {
      this.tableCheckLoading = true;
      getCheckDetailList({ tenantId: getItem('orgId'), checkId: this.checkId }).then(res => {
        if (res.code === 200) {
          this.tableCheckLoading = false;
          this.tableCheckData = res.data;
        }
      });
    },
    //删除接口
    deleteCheckDetail(id) {
      //配置项的具体配置列表
      deleteCheckDetail({ id }).then(res => {
        if (res.code === 200) {
          this.btnLoading = false;
          this.$message.success('删除成功');
          this.getCheckDetailList();
        }
      });
    }
  },

  watch: {
    dialogVisible(val) {
      this.checkVisible = val;
    }
  },

  computed: {
    dialogTitle() {
      return this.title + '配置';
    }
  }
};
</script>

<style scoped>
.tabel-tilte {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #303133;
}
.footer-btn-grounp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
}
.add-btn {
  width: 94px;
  height: 32px;
  border: 1px solid #005aab;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  color: #005aab;
  font-size: 14px;
  cursor: pointer;
}
</style>
