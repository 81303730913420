export const validateRules = {
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入检查表名称'));
      } else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };

    var validateNickname = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入简称'));
      } else if (value.length > 10) {
        return callback(new Error('最大字符为10'));
      }
      callback();
    };
    var validatePoints = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入分值'));
      } else if (!Number.isInteger(Number(value)) || Number(value) < 1 || Number(value) > 100) {
        return callback(new Error('请输入1-100的整数'));
      }
      callback();
    };

    return {
      rules: {
        name: [{ validator: validateName, trigger: 'blur' }],
        nickname: [{ validator: validateNickname, trigger: 'blur' }],
        points: [{ validator: validatePoints, trigger: 'blur' }]
      }
    };
  }
};
