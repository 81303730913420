var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"修改检查表","visible":_vm.checkVisible,"close-on-click-modal":false,"width":"1024px"},on:{"update:visible":function($event){_vm.checkVisible=$event},"close":_vm.dialogClose}},[_c('p',{staticClass:"tabel-tilte"},[_vm._v(_vm._s(_vm.title))]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableCheckLoading),expression:"tableCheckLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableCheckData,"size":"medium","border":"","max-height":"485px"}},[_c('el-table-column',{attrs:{"prop":"checkItem","label":"检查项目","align":"center","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"点击输入"},model:{value:(row.checkItem),callback:function ($$v) {_vm.$set(row, "checkItem", $$v)},expression:"row.checkItem"}})]}}])}),_c('el-table-column',{attrs:{"prop":"deductionStandard","label":"扣分标准","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"点击输入","type":"textarea","maxlength":500,"autosize":{ minRows: 6 }},model:{value:(row.deductionStandard),callback:function ($$v) {_vm.$set(row, "deductionStandard", $$v)},expression:"row.deductionStandard"}})]}}])}),_c('el-table-column',{attrs:{"prop":"score","label":"应得分数","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"点击输入"},model:{value:(row.score),callback:function ($$v) {_vm.$set(row, "score", $$v)},expression:"row.score"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('span',{staticClass:"view-details",staticStyle:{"color":"#F56C6C"},on:{"click":function($event){return _vm.deleteConfig(row.id, $index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"footer-btn-grounp",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"add-btn",on:{"click":_vm.handelConfigCheck}},[_vm._v("添加配置项")]),_c('div',[_c('el-button',{on:{"click":_vm.handelClose}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.handleCheckConfig}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }