<template>
  <div>
    <div class="add-btn">
      <el-button type="primary" icon="el-icon-plus" @click="addScore">新增</el-button>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" size="medium" v-loading="tabelLoading" show-summary>
      <el-table-column label="序号" width="120" fixed align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="检查表名称" fixed align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="nickname" label="简称" fixed align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="points" label="分值" fixed align="center"> </el-table-column>
      <el-table-column label="操作" fixed="right" width="250" align="center">
        <template slot-scope="{ row }">
          <div class="btn-grounp">
            <span class="view-details" @click="handelUpdateCheck(row)">修改检查表</span>
            <span class="view-details" @click="handelUpdate(row)">修改</span>
            <span @click="handelDelete(row.id)" style="color:#F56C6C;cursor: pointer;">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增dialog -->
    <add-dialog
      ref="addRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      @handel-success="handelSuccess"
    ></add-dialog>
    <!-- 修改检查表dialog -->
    <update-check-tabel ref="checkRef" :dialog-visible.sync="dialogCheckVisible"></update-check-tabel>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { getItem } from '@/utils/storage.js';
import { getSafetyCheckList, deleteSafetyCheck } from 'api/super-admin/sys-setting/score-setting.js';
import addDialog from './addDialog';
import updateCheckTabel from './updateCheckTabel';
export default {
  components: {
    addDialog,
    updateCheckTabel
  },
  data() {
    return {
      tableData: [],
      tabelLoading: false,
      dialogVisible: false, //新增
      dialogCheckVisible: false, //修改检查表
      tabelParams: {
        tenantId: getItem('orgId')
      },
      //dialog参数
      dialogTitle: '',
      dialogVisible: false
    };
  },
  methods: {
    handelSuccess() {
      //编辑或者新增成功后的回调
      this.getSafetyCheckList();
    },
    handelUpdateCheck({ id, name }) {
      //修改检查表
      this.dialogCheckVisible = true;
      this.$refs.checkRef.initTabelConfigFunc(id,name);
    },
    handelUpdate(row) {
      //修改
      this.$refs.addRef.initFormDetail(cloneDeep(row));
      this.dialogVisible = true;
      this.dialogTitle = '修改';
    },
    addScore() {
      //新增
      this.dialogVisible = true;
      this.dialogTitle = '新增';
    },
    handelDelete(id) {
      //删除
      this.$confirm('请确认是否删除该评分表?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteSafetyCheck(id);
      });
    },
    getSafetyCheckList() {
      //列表
      this.tabelLoading = true;
      getSafetyCheckList(this.tabelParams).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          this.tableData = res.data.safetyCheckList;
        }
      });
    },
    //删除接口
    deleteSafetyCheck(id) {
      deleteSafetyCheck({ id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.getSafetyCheckList();
        }
      });
    }
  },
  created() {
    this.getSafetyCheckList();
  }
};
</script>

<style scoped lang="less">
.add-btn {
  margin: 16px 0;
}
.btn-grounp {
  display: flex;
  span {
    flex: 1;
  }
}
</style>
